import { StaticQuery, graphql } from "gatsby"
import React from "react"
import Link from "components/Link"

import dayjs from "dayjs"

import {
  Section,
  Divider,
  LargeCardContainer,
  HighlightedLink,
  SEO,
  Card,
} from "components"

const BLOG_PAGE_QUERY = graphql`
  query BlogPageQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog//" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                resize(width: 266, jpegQuality: 90) {
                  src
                }
              }
            }
          }
        }
      }
    }
    allMediumPostsJson {
      edges {
        node {
          id
          virtuals {
            subtitle
            previewImage {
              imageId
            }
          }
          title
          uniqueSlug
          latestPublishedAt
        }
      }
    }
  }
`

const Posts = props => {
  return (
    <StaticQuery
      query={BLOG_PAGE_QUERY}
      render={data => {
        const blogPosts = data.allMdx.edges
        const posts = data.allMediumPostsJson.edges

        const blogPostComponents = blogPosts.map(({ node }, index) => {
          return (
            <Card
              title={node.frontmatter.title}
              key={"blogPost" + index}
              publishedDate={node.frontmatter.date}
              href={node.fields.slug}
              imageSrc={
                node.frontmatter.featuredImage.childImageSharp.resize.src
              }
            />
          )
        })

        const mediumPostComponents = posts.map(({ node }, index) => {
          return (
            <Card
              external
              title={node.title}
              key={"postKey" + index}
              publishedDate={dayjs(node.latestPublishedAt).format(
                "MMM DD, YYYY"
              )}
              href={`https://medium.com/lucasmcgartland/${node.uniqueSlug}`}
              imageSrc={`https://cdn-images-1.medium.com/max/500/${node.virtuals.previewImage.imageId}`}
            />
          )
        })

        const allPosts = [...blogPostComponents, ...mediumPostComponents]
        return allPosts
      }}
    />
  )
}

export default Posts
